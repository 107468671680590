.contact-button {
    text-decoration: none;
    color: rgb(39, 39, 39);
    background-color: rgba(255,222,52, 100%);
    padding: .8em 1em;
    border-radius: 8px;
    transition: .5s background-color;
    font-weight: 700;
    display: inline-block;
}

.contact-button:hover {
    background-color: rgba(255,222,52, 80%);
    transition: .5s background-color;
}