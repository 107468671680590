.Nav-Bar {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
}

.Nav-Links {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
    gap: calc(20px + 2vmin);
}

.Nav-Links a {
    text-decoration: none;
    color: inherit;
}

.Nav-Links a:hover {
    opacity: 70%;
    color: dodgerblue;
}